const CalendarOnboardingSvg = (): React.ReactElement => {
  return (
    <svg
      width="30"
      height="26"
      viewBox="0 0 30 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="calendar-icon svg-primary-color"
    >
      <g clipPath="url(#clip0_408_2320)">
        <path d="M9.17612 2.09522V3.63491H15.3473V2.09522C15.3473 1.24503 16.0367 0.555542 16.8901 0.555542C17.7434 0.555542 18.4329 1.24503 18.4329 2.09522V3.63491H20.7471C22.0247 3.63491 23.0613 4.6689 23.0613 5.94443V9.79364H1.46216V5.94443C1.46216 4.6689 2.49824 3.63491 3.77635 3.63491H6.09054V2.09522C6.09054 1.24503 6.77997 0.555542 7.63333 0.555542C8.48669 0.555542 9.17612 1.24503 9.17612 2.09522ZM29.2324 18.2619C29.2324 22.087 26.1227 25.1905 22.2899 25.1905C18.457 25.1905 15.3473 22.087 15.3473 18.2619C15.3473 14.4367 18.457 11.3333 22.2899 11.3333C26.1227 11.3333 29.2324 14.4367 29.2324 18.2619ZM21.4751 15.1825V18.2619C21.4751 18.6853 21.8656 19.0317 22.2465 19.0317H24.6041C25.0283 19.0317 25.3754 18.6853 25.3754 18.2619C25.3754 17.8385 25.0283 17.4921 24.6041 17.4921H23.0179V15.1825C23.0179 14.7591 22.7141 14.4127 22.2465 14.4127C21.8656 14.4127 21.4751 14.7591 21.4751 15.1825Z" />
        <path
          opacity="0.4"
          d="M13.8045 18.2619C13.8045 21.1247 15.2316 23.6556 17.4108 25.1905H3.77635C2.49824 25.1905 1.46216 24.156 1.46216 22.8809V9.79364H22.2899C17.6036 9.79364 13.8045 13.5851 13.8045 18.2619ZM23.0613 9.79364V9.82732C22.8057 9.80326 22.5502 9.79364 22.2899 9.79364H23.0613Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_408_2320">
          <rect width="27.7703" height="24.6349" fill="white" transform="translate(1.46216 0.555542)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CalendarOnboardingSvg;
