const FaceOnboardingSvg = (): React.ReactElement => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="face-icon svg-primary-color"
    >
      <path d="M15.75 7C10.9156 7 7 10.9156 7 15.75C7 20.5844 10.9173 24.5 15.75 24.5C20.5827 24.5 24.5 20.5827 24.5 15.75C24.5 10.9173 20.5844 7 15.75 7ZM18.8125 12.25C19.5371 12.25 20.125 12.8379 20.125 13.5625C20.125 14.2871 19.5398 14.875 18.8125 14.875C18.0852 14.875 17.5 14.2898 17.5 13.5625C17.5 12.8352 18.0852 12.25 18.8125 12.25ZM12.6875 12.25C13.4148 12.25 14 12.8352 14 13.5625C14 14.2898 13.4148 14.875 12.6875 14.875C11.9602 14.875 11.375 14.2898 11.375 13.5625C11.375 12.8352 11.9602 12.25 12.6875 12.25ZM20.3164 18.9602C19.1516 20.2563 17.4891 21 15.75 21C14.0109 21 12.3473 20.2568 11.1852 18.9602C10.8609 18.5992 10.8938 18.0469 11.2547 17.7242C11.6135 17.4004 12.1658 17.4311 12.4901 17.7909C14.1515 19.6453 17.354 19.6453 19.0143 17.7909C19.3373 17.432 19.8909 17.4021 20.2497 17.7242C20.6084 18.0463 20.6391 18.5992 20.3164 18.9602Z" />
      <path
        opacity="0.4"
        d="M9.4375 27.375H4.625V22.5625C4.625 21.8352 4.03711 21.25 3.3125 21.25C2.58789 21.25 2 21.8352 2 22.5625V28.25C2 29.2163 2.78367 30 3.75 30H9.4375C10.1648 30 10.75 29.4148 10.75 28.6875C10.75 27.9602 10.1648 27.375 9.4375 27.375ZM3.26328 10.75C4.03711 10.75 4.625 10.1648 4.625 9.4375V4.625H9.4375C10.1648 4.625 10.75 4.03711 10.75 3.3125C10.75 2.58789 10.1648 2 9.4375 2H3.75C2.78367 2 2 2.78367 2 3.75V9.4375C2 10.1648 2.58789 10.75 3.26328 10.75ZM28.25 2H22.5625C21.8352 2 21.25 2.58789 21.25 3.26328C21.25 3.93867 21.8352 4.625 22.5625 4.625H27.375V9.4375C27.375 10.1648 27.9602 10.75 28.6875 10.75C29.4148 10.75 30 10.1648 30 9.4375V3.75C30 2.78367 29.218 2 28.25 2ZM28.6875 21.25C27.9629 21.25 27.375 21.8379 27.375 22.5625V27.375H22.5625C21.8379 27.375 21.25 27.9629 21.25 28.6875C21.25 29.4121 21.8352 30 22.5625 30H28.25C29.2163 30 30 29.2163 30 28.25V22.5625C30 21.8352 29.4148 21.25 28.6875 21.25Z"
      />
    </svg>
  );
};

export default FaceOnboardingSvg;
