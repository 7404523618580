interface ImageProps {
  src: string;
  className?: string;
  alt?: string;
  testid?: string;
}

const Image: React.FC<ImageProps> = ({ src, className, alt, testid }: ImageProps) => {
  return (
    <img
      src={src}
      className={`image-component ${className ?? ''}`}
      data-testid={`${testid ?? ''}`}
      data-auto={`${testid ?? ''}`}
      alt={alt || 'image'}
    />
  );
};

export default Image;
