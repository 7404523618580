const ListOnboardingSvg = (): React.ReactElement => {
  return (
    <svg
      width="28"
      height="24"
      viewBox="0 0 28 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="list-icon svg-primary-color"
    >
      <path d="M4.47563 6.56815C4.23445 6.83065 3.8943 6.98924 3.53445 6.95096C3.17461 7.01112 2.8268 6.86893 2.57195 6.56815L0.384398 4.38065C-0.128133 3.91581 -0.128133 3.08456 0.384398 2.57213C0.896875 2.05971 1.72813 2.05971 2.24055 2.57213L3.44969 3.77909L6.46406 0.4344C6.94531 -0.104272 7.77656 -0.148022 8.31797 0.337056C8.85391 0.822134 8.89766 1.65174 8.41094 2.19096L4.47563 6.56815ZM4.47563 15.3182C4.23445 15.5807 3.8943 15.7392 3.53445 15.701C3.17461 15.7611 2.8268 15.6189 2.57195 15.3182L0.384398 13.1307C-0.128133 12.6658 -0.128133 11.8346 0.384398 11.3205C0.896875 10.8119 1.72813 10.8119 2.24055 11.3205L3.44969 12.5291L6.46406 9.18221C6.94531 8.64628 7.77656 8.60253 8.31797 9.08924C8.85391 9.57049 8.89766 10.4017 8.41094 10.9432L4.47563 15.3182ZM0 21.0002C0 19.551 1.17523 18.3752 2.625 18.3752C4.07477 18.3752 5.25 19.551 5.25 21.0002C5.25 22.4494 4.07477 23.6252 2.625 23.6252C1.17523 23.6252 0 22.4494 0 21.0002Z" />
      <path
        opacity="0.4"
        d="M10.5 19.2502H26.25C27.218 19.2502 28 20.0322 28 21.0002C28 21.9682 27.218 22.7502 26.25 22.7502H10.5C9.53203 22.7502 8.75 21.9682 8.75 21.0002C8.75 20.0322 9.53203 19.2502 10.5 19.2502ZM14 10.5002H26.25C27.218 10.5002 28 11.2822 28 12.2502C28 13.2182 27.218 14.0002 26.25 14.0002H14C13.032 14.0002 12.25 13.2182 12.25 12.2502C12.25 11.2822 13.032 10.5002 14 10.5002ZM14 5.25021C13.032 5.25021 12.25 4.46818 12.25 3.50021C12.25 2.53389 13.032 1.75021 14 1.75021H26.25C27.218 1.75021 28 2.53389 28 3.50021C28 4.46818 27.218 5.25021 26.25 5.25021H14Z"
      />
    </svg>
  );
};

export default ListOnboardingSvg;
